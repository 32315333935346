import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


import 'primereact/resources/themes/saga-blue/theme.css';  // Cambia el tema según lo que necesites
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';  // Para los íconos de PrimeReact
import Home from './components/home/Home';
import MainView from './components/mainview/MainView';
import { AuthProvider } from './context/AuthContext';
import { BudgetProvider } from './context/BudgetContext';
import { GeneralProvider } from './context/GeneralContext';
import Login from './components/login&auth/Login';
import Articles from './components/articles/Articles';
import ResetPassword from './components/login&auth/ResetPassword';
import Users from './components/users/Users';
import Budget from './components/budget/Budget';
import Clients from './components/clients/Clients';
import BudgetsList from './components/budgetsList/BudgetsList';
import Providers from './components/providers/Providers';
import PurchaseOrders from './components/purchaseOrders/PurchaseOrders';
import ReceiptNotes from './components/receiptNotes/ReceiptNotes';
import Expenses from './components/expenses/Expenses';
import Cash from './components/cash/Cash';
import Treasury from './components/treasury/Treasury';
import Reports from './components/reports/Reports';
import BudgetPreview from './components/budgetPreview/BudgetPreview';
import PayModal from './components/payModal/PayModal';

const App = () => {
  return (
    <GeneralProvider>
      <AuthProvider>
        <BudgetProvider>
          <Router>
            <div>
              <MainView>
                <Routes>

                  <Route path="/reset_password/:token" element={<ResetPassword />} />
                  <Route path="/login" element={<Login />} />

                  <Route path="/home" element={<Home />} />

                  <Route path="/articles" element={<Articles />} />
                  <Route path="/budget" element={<Budget />} />
                  <Route path="/budgets-list" element={<BudgetsList />} />

                  <Route path="/purchase-orders" element={<PurchaseOrders />} />
                  <Route path="/receipt-notes" element={<ReceiptNotes />} />
                  <Route path="/expenses" element={<Expenses />} />
                  <Route path="/cash" element={<Cash />} />
                  <Route path="/treasury" element={<Treasury />} />
                  <Route path="/reports" element={<Reports />} />

                  <Route path="/users" element={<Users />} />
                  <Route path="/clients" element={<Clients />} />
                  <Route path="/providers" element={<Providers />} />

                </Routes>
              </MainView>

              <BudgetPreview />
              <PayModal />
            </div>
          </Router>
        </BudgetProvider>
      </AuthProvider>
    </GeneralProvider>
  );
};

export default App;
