import React from 'react';

const Expenses = () => {
    return (
        <div>
            Expenses
        </div>
    );
};

export default Expenses;