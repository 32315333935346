import Cookies from 'js-cookie';

// Obtener el usuario de las cookies
export const getUserFromCookies = () => {
    const user = Cookies.get('user');
    if (!user) return null;
    return JSON.parse(user);
};

// Verificar si el usuario pertenece a un grupo específico
export const hasRole = (roleNames = []) => {
    const user = getUserFromCookies();
    if (!user) return false;
    const groups = user.user_data?.grupos || [];
    // Verifica si al menos un rol de la lista coincide con los grupos del usuario
    return roleNames.some(role => 
        groups.some(group => group.name.toUpperCase() === role.toUpperCase())
    );
};

// Obtener los grupos del usuario como una cadena separada por comas
export const getUserRoles = () => {
    const user = getUserFromCookies();
    if (!user) return '';
    const groups = user.user_data?.grupos || [];
    
    // Función para convertir a "Title Case" (Primera letra mayúscula)
    const toTitleCase = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    
    // Mapea los nombres de los grupos y los une con comas
    return groups.map(group => toTitleCase(group.name)).join(', ');
};