import api from '../../helpers/axiosConfig';
import Cookies from 'js-cookie';

// Función para obtener la lista de clientes
export const getClients = async () => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible'); // Manejo si el token no está disponible
        }

        const response = await api.get('/clientes/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`, // Incluir el token de autorización
            }
        });

        return response.data; // Devuelve los datos de la respuesta
    } catch (error) {
        throw error; // Lanza el error para que pueda ser manejado en el componente
    }
};

// Función para crear un nuevo cliente
export const createClient = async (clientData) => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible'); // Manejo si el token no está disponible
        }

        // Construir los datos que se enviarán al backend dependiendo si es empresa o persona
        const bodyData = {
            empresa: clientData.empresa,
            cuit: clientData.empresa ? clientData.cuit : undefined,  // Solo agregar cuit si es empresa
            razon_social: clientData.empresa ? clientData.razon_social : undefined, // Solo agregar razon_social si es empresa
            cuil_dni: clientData.empresa ? undefined : clientData.cuil_dni,  // Solo agregar cuil_dni si es persona
            nombre: clientData.empresa ? undefined : clientData.nombre, // Solo agregar nombre si es persona
            apellido: clientData.empresa ? undefined : clientData.apellido, // Solo agregar apellido si es persona
            telefono: clientData.telefono,
            domicilio: clientData.domicilio
        };

        // Realizar la solicitud POST a la API para crear el cliente
        const response = await api.post('/clientes/', bodyData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`, // Incluir el token de autorización
            }
        });

        return response; // Devuelve los datos de la respuesta
    } catch (error) {
        throw error; // Lanza el error para que pueda ser manejado en el componente
    }
};

// Función para editar un cliente
export const editClient = async (clientId, clientData) => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible'); // Manejo si el token no está disponible
        }

        // Realizar la solicitud PATCH a la API para editar el cliente
        const response = await api.patch(`/clientes/${clientId}/`, clientData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`, // Incluir el token de autorización
            }
        });

        return response.data; // Devuelve los datos del cliente editado
    } catch (error) {
        if (error.response && error.response.status === 404) {
            throw new Error('El cliente no existe'); // Manejo específico para el error 404
        }
        throw error; // Lanza el error para que pueda ser manejado en el componente
    }
};


// Función para obtener los detalles de un cliente por su ID
export const getClientById = async (clientId) => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible'); // Manejo si el token no está disponible
        }

        // Realizar la solicitud GET a la API para obtener el cliente por su ID
        const response = await api.get(`/clientes/${clientId}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`, // Incluir el token de autorización
            }
        });

        return response.data; // Devuelve los datos del cliente
    } catch (error) {
        throw error; // Lanza el error para que pueda ser manejado en el componente
    }
};

// Función para activar la cuenta corriente de un cliente
export const activateAccount = async (clientId, { plazo_cobro, limite }) => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible'); // Manejo si el token no está disponible
        }

        // Realizar la solicitud POST a la API para activar la cuenta corriente
        const response = await api.post(
            `/clientes/${clientId}/activar_cuenta_corriente/`,
            { plazo_cobro, limite }, // Cuerpo de la solicitud
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`, // Incluir el token de autorización
                }
            }
        );

        return response.data; // Devuelve los datos de la respuesta
    } catch (error) {
        throw error; // Lanza el error para que pueda ser manejado en el componente
    }
};