import React, { useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { useBudget } from '../../context/BudgetContext';
import { useGeneral } from '../../context/GeneralContext';
import PdfContent from './PdfContent';
import { sendToCash, returnToDraft, changeException } from '../budget/budgetServices';
import Swal from 'sweetalert2';
import { InputSwitch } from 'primereact/inputswitch';
import { hasRole } from '../../helpers/usersHelper';

const BudgetPreview = () => {
  const { budgetDetails, closeBudgetDetails, setUpdateList, openBudgetToPay, refreshbudgetDetails } = useBudget();
  const { generalData } = useGeneral();
  const estados_presupuestos = generalData?.estados_presupuestos || [];
  const [showIva, setShowIva] = useState(true);

  const handleDownload = async () => {
    const pdfContent = document.getElementById('pdf-content');
    if (!pdfContent) return;

    const canvas = await html2canvas(pdfContent, { scale: 2 });
    const imgData = canvas.toDataURL('image/png');

    const doc = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

    const budgetNumber = budgetDetails?.codigo_presupuesto || '000000';
    doc.save(`Presupuesto ${budgetNumber}.pdf`);
  };

  const handleSendToCash = async () => {
    closeBudgetDetails();
    const confirm = await Swal.fire({
      title: '¿Estás seguro?',
      text: '¿Deseas enviar este presupuesto a caja?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, enviar',
      cancelButtonText: 'Cancelar',
    });

    if (confirm.isConfirmed) {
      try {
        await sendToCash(budgetDetails.id);
        setUpdateList(true);
        setTimeout(() => {
          refreshbudgetDetails();
        }, 1500);
      } catch (error) {
        console.error('Error al enviar a caja:', error);
      }
    }
  };

  const handleReject = async () => {
    closeBudgetDetails();
    const confirm = await Swal.fire({
      title: '¿Estás seguro?',
      text: '¿Deseas rechazar este presupuesto y retornarlo a borrador?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, rechazar',
      cancelButtonText: 'Cancelar',
    });

    if (confirm.isConfirmed) {
      try {
        await returnToDraft(budgetDetails.id);
        setUpdateList(true);
        setTimeout(() => {
          refreshbudgetDetails();
        }, 1500);
      } catch (error) {
        console.error('Error al rechazar:', error);
      }
    }
  };

  const handleChangeException = async (previousState) => {
    const action = previousState ? 'Deshabilitar' : 'Habilitar';
    const actionDone = previousState ? 'Deshabilitada' : 'Habilitada';
    closeBudgetDetails();
    const confirm = await Swal.fire({
      title: `¿${action} Excepción?`,
      text:
        action === 'Habilitar'
          ? 'Esta acción permitirá realizar el cobro aunque supere el margen.'
          : 'Esta acción deshabilitará la excepción de cobro.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${action} Excepción`,
      cancelButtonText: 'Cancelar',
    });

    if (confirm.isConfirmed) {
      try {
        await changeException(budgetDetails.id);
        await Swal.fire({
          icon: 'success',
          title: `Excepción ${actionDone}`,
          text: `La excepción ha sido ${actionDone} correctamente.`,
        });
        setUpdateList(true);
        setTimeout(() => {
          refreshbudgetDetails();
        }, 1500);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Hubo un problema al ${action} la excepción.`,
        });
        console.error(`Error al ${action} la excepción:`, error);
      }
    }
  };

  const getEstadoNombre = (id) => {
    const estado = estados_presupuestos.find((e) => e.id === id);
    return estado ? estado.nombre : null;
  };

  const estadoActual = getEstadoNombre(budgetDetails?.estado_presupuesto);

  const handleOpenPayModal = () => {
    if (estadoActual === 'CAJA') {
      openBudgetToPay(budgetDetails.id);
      closeBudgetDetails();
    }
  };

  return (
    <Dialog
      visible={!!budgetDetails}
      onHide={closeBudgetDetails}
      header="Previsualización del Presupuesto"
      style={{ width: '80vw' }}
    >
      <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', gap: '15px' }}>
        <InputSwitch
          checked={showIva}
          onChange={(e) => setShowIva(e.value)}
          tooltip="Incluir IVA"
        />
        <label style={{ fontSize: '14px', color: 'gray' }}>Mostrar IVA</label>
      </div>

      <PdfContent budgetDetails={budgetDetails} showIva={showIva} />

      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '100%', display: 'flex', gap: '10px', justifyContent: 'end' }}>
          {estadoActual === 'BORRADOR' && hasRole(['ADMIN', 'VENDEDOR']) && (
            <Button
              label="Enviar a caja"
              icon="pi pi-wallet"
              onClick={handleSendToCash}
              className="p-button-success  p-button-rounded"
            />
          )}

          {/* {estadoActual === 'CAJA' && (
            <Button
              label={budgetDetails?.excepcion ? "Deshabilitar Excepción" : "Habilitar Excepción"}
              icon="pi pi-exclamation-circle"
              onClick={() => handleChangeException(budgetDetails?.excepcion)}
              className="p-button-warning  p-button-rounded"
            />
          )} */}

          {estadoActual === 'CAJA' && (
            <>
              {hasRole(['ADMIN', 'CAJA']) && (
                <Button
                  label="Rechazar"
                  icon="pi pi-times"
                  onClick={handleReject}
                  className="p-button-danger  p-button-rounded"
                />
              )}
              {hasRole(['ADMIN', 'CAJA']) && (
                <Button
                  label="Cobrar"
                  icon="pi pi-dollar"
                  onClick={handleOpenPayModal}
                  className="p-button-primary  p-button-rounded"
                />
              )}
            </>
          )}

          <Button
            label="Descargar PDF"
            onClick={handleDownload}
            icon="pi pi-download"
            className="p-button-primary p-button-rounded"
          />
          <Button
            label="Cerrar"
            onClick={closeBudgetDetails}
            icon="pi pi-times"
            className="p-button-text p-button-secondary"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default BudgetPreview;
