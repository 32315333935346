import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext'; // Importar InputText de PrimeReact
import { Button } from 'primereact/button'; // Importar Button de PrimeReact
import './formClient.css';
import { createClient, editClient } from './clientsServices';
import { useBudget } from '../../context/BudgetContext';
import Swal from 'sweetalert2';

const FormClient = ({ close, refreshClients, selectedClient }) => {
    const { setBudgetClient } = useBudget();

    const [isEmpresa, setIsEmpresa] = useState(false); // Estado para saber si es una empresa o una persona
    const [cuil, setCuil] = useState(''); // Estado para CUIL o CUIT
    const [nombre, setNombre] = useState(''); // Estado para Nombre (solo para persona)
    const [apellido, setApellido] = useState(''); // Estado para Apellido (solo para persona)
    const [razonSocial, setRazonSocial] = useState(''); // Estado para Razón Social (solo para empresa)
    const [telefono, setTelefono] = useState(''); // Estado para Teléfono
    const [domicilio, setDomicilio] = useState(''); // Estado para Domicilio
    const [error, setError] = useState(''); // Estado para manejar errores

    // Efecto para llenar los campos si `selectedClient` está definido
    useEffect(() => {
        if (selectedClient) {
            setIsEmpresa(selectedClient.empresa || false);
            setCuil(selectedClient.cuit || selectedClient.cuil_dni || '');
            setNombre(selectedClient.nombre || '');
            setApellido(selectedClient.apellido || '');
            setRazonSocial(selectedClient.razon_social || '');
            setTelefono(selectedClient.telefono || '');
            setDomicilio(selectedClient.domicilio || '');
        }
    }, [selectedClient]);

    // Función para manejar el envío del formulario
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevenir el comportamiento predeterminado del formulario

        const clientData = {
            empresa: isEmpresa,
            cuit: isEmpresa ? cuil : null, // Enviar cuit si es empresa
            razon_social: isEmpresa ? razonSocial : null,
            cuil_dni: isEmpresa ? null : cuil, // Enviar cuil_dni si es persona
            nombre: isEmpresa ? null : nombre,
            apellido: isEmpresa ? null : apellido,
            telefono,
            domicilio
        };

        try {
            if (selectedClient) {
                // Editar cliente existente
                await editClient(selectedClient.id, clientData); // Llama a la función para editar el cliente
                Swal.fire({
                    icon: 'success',
                    title: 'Cliente actualizado exitosamente',
                    showConfirmButton: false,
                    timer: 1500
                });
            } else {
                // Crear un nuevo cliente
                const res = await createClient(clientData); // Llama a la función para agregar el cliente
                if (res.status === 201) {
                    setBudgetClient(res.data);
                    Swal.fire({
                        icon: 'success',
                        title: 'Cliente creado exitosamente',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            }

            refreshClients(); // Refrescar la lista de clientes
            close(); // Cerrar el modal
        } catch (error) {
            console.error('Error al procesar cliente:', error);
            setError(error.message || 'Error al procesar cliente'); // Manejo de errores
        }
    };

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit}>
                <div className="form-container">
                    <div className="form-group-client">
                        <span className="form-label">Tipo:</span>
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <div>
                                <input
                                    className='cursor-pointer'
                                    type="radio"
                                    id="persona"
                                    name="tipoCliente"
                                    checked={!isEmpresa}
                                    onChange={() => setIsEmpresa(false)}
                                />
                                <label htmlFor="persona">Persona</label>
                            </div>
                            <div>
                                <input
                                    className='cursor-pointer'
                                    type="radio"
                                    id="empresa"
                                    name="tipoCliente"
                                    checked={isEmpresa}
                                    onChange={() => setIsEmpresa(true)}
                                />
                                <label htmlFor="empresa">Empresa</label>
                            </div>
                        </div>
                    </div>

                    {isEmpresa ? (
                        <>
                            <div className="form-group-client">
                                <span className="form-label">CUIT:</span>
                                <InputText
                                    placeholder="CUIT"
                                    className="input-field"
                                    value={cuil}
                                    onChange={(e) => setCuil(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group-client">
                                <span className="form-label">Razón Social:</span>
                                <InputText
                                    placeholder="Razón Social"
                                    className="input-field"
                                    value={razonSocial}
                                    onChange={(e) => setRazonSocial(e.target.value)}
                                    required
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="form-group-client">
                                <span className="form-label">CUIL/DNI:</span>
                                <InputText
                                    placeholder="CUIL/DNI"
                                    className="input-field"
                                    value={cuil}
                                    onChange={(e) => setCuil(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group-client">
                                <span className="form-label">Nombre:</span>
                                <InputText
                                    placeholder="Nombre"
                                    className="input-field"
                                    value={nombre}
                                    onChange={(e) => setNombre(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group-client">
                                <span className="form-label">Apellido:</span>
                                <InputText
                                    placeholder="Apellido"
                                    className="input-field"
                                    value={apellido}
                                    onChange={(e) => setApellido(e.target.value)}
                                    required
                                />
                            </div>
                        </>
                    )}

                    <div className="form-group-client">
                        <span className="form-label">Teléfono:</span>
                        <InputText
                            placeholder="Teléfono"
                            className="input-field"
                            value={telefono}
                            onChange={(e) => setTelefono(e.target.value)}
                        />
                    </div>

                    <div className="form-group-client">
                        <span className="form-label">Domicilio:</span>
                        <InputText
                            placeholder="Domicilio"
                            className="input-field"
                            value={domicilio}
                            onChange={(e) => setDomicilio(e.target.value)}
                        />
                    </div>
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>} {/* Mostrar mensaje de error si existe */}
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', gap: "12px" }}>
                    <Button label="Cancelar" onClick={close} icon="pi pi-times" className="p-button-text" />
                    <Button label={selectedClient ? "Actualizar" : "Agregar"} type="submit" icon="pi pi-check" className="p-button-text" />
                </div>
            </form>
        </div>
    );
};

export default FormClient;
