// src/context/AuthContext.js

import { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import { logoutUser } from '../components/login&auth/authServices';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        // Leer el usuario de las cookies al iniciar
        const userCookie = Cookies.get('user');
        return userCookie ? JSON.parse(userCookie) : null;
    });

    const login = (userData) => {
        setUser(userData);
        Cookies.set('user', JSON.stringify(userData), { expires: 1 }); // Guardar el usuario en las cookies por 1 día
    };

    const logout = async () => {
        try {
            const token = user.user_data.token || null; // Asegúrate de que el token esté disponible
            if (token) {
                const response = await logoutUser(token); // Llama a la función logoutUser

                if (response.status === "success") {
                    // Solo limpiar y redirigir si el logout fue exitoso
                    setUser(null);
                    Cookies.remove('user'); // Eliminar el usuario de las cookies al cerrar sesión
                    setTimeout(() => {
                        window.location.href = '/login'; // Redirigir a la página de login
                    }, 1000);
                } else {
                    // Manejar el caso de un logout fallido
                    console.error('Error al cerrar sesión:', response);
                    alert('No se pudo cerrar sesión. Intenta nuevamente.');
                }
            }
        } catch (error) {
            console.error('Error al cerrar sesión:', error); // Manejo de errores
            alert('Ocurrió un error al intentar cerrar sesión.'); // Mensaje al usuario
        }
    };


    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
