import api from '../../helpers/axiosConfig';
import Cookies from 'js-cookie';

// Función para obtener la lista de proveedores
export const getProviders = async () => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible'); // Manejo si el token no está disponible
        }

        // Realizar la solicitud GET a la API para obtener los proveedores
        const response = await api.get('/proveedores/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`, // Incluir el token de autorización
            }
        });

        return response.data; // Devuelve los datos de la respuesta
    } catch (error) {
        throw error; // Lanza el error para que pueda ser manejado en el componente
    }
};

// Función para crear un nuevo proveedor
export const createProvider = async (providerData) => {

    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible'); // Manejo si el token no está disponible
        }

        // Realizar la solicitud POST a la API para crear el proveedor
        const response = await api.post('/proveedores/', providerData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`, // Incluir el token de autorización
            }
        });

        return response; // Devuelve los datos de la respuesta
    } catch (error) {        
        throw error; // Lanza el error para que pueda ser manejado en el componente
    }
};

// Función para obtener la lista de tipos de proveedores
export const getProvidersTypes = async () => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible'); // Manejo si el token no está disponible
        }

        // Realizar la solicitud GET a la API para obtener los tipos de proveedores
        const response = await api.get('/tipo_proveedores/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`, // Incluir el token de autorización
            }
        });

        return response.data; // Devuelve los datos de la respuesta
    } catch (error) {
        throw error; // Lanza el error para que pueda ser manejado en el componente
    }
};

// Función para editar un proveedor
export const editProvider = async (providerId, providerData) => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible'); // Manejo si el token no está disponible
        }

        // Realizar la solicitud PATCH a la API para editar el proveedor
        const response = await api.patch(`/proveedores/${providerId}/`, providerData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`, // Incluir el token de autorización
            }
        });

        return response.data; // Devuelve los datos de la respuesta
    } catch (error) {
        throw error; // Lanza el error para que pueda ser manejado en el componente
    }
};


// Función para eliminar un proveedor
export const deleteProvider = async (providerId) => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible'); // Manejo si el token no está disponible
        }

        // Realizar la solicitud DELETE a la API para eliminar el proveedor
        const response = await api.delete(`/proveedores/${providerId}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`, // Incluir el token de autorización
            }
        });

        if (response.status === 204) {
            return {
                status: 'success',
                message: 'Proveedor eliminado exitosamente!'
            };
        }
    } catch (error) {
        if (error.response && error.response.status === 404) {
            throw new Error("No Proveedor matches the given query.");
        } else {
            throw error; // Lanza otros errores para que puedan ser manejados
        }
    }
};

