import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox'; // Cambiar ToggleButton a Checkbox
import { useBudget } from '../../context/BudgetContext'; // Importar el contexto
import { useGeneral } from '../../context/GeneralContext'; // Importar para acceder a IVA
import { payBudget } from '../budget/budgetServices'; // Importar la función para pagar el presupuesto
import Swal from 'sweetalert2'; // Para alertas de éxito o error
import './payModal.css'; // Archivo CSS para estilos personalizados

const PayModal = () => {
    const { budgetToPay, closeBudgetToPay, setUpdateList } = useBudget();
    const { generalData } = useGeneral();
    const iva = generalData?.iva || 21; // Obtener el valor del IVA, por defecto 21%
    const paymentMethods = generalData?.metodos_pago || []; // Obtener métodos de pago desde generalData

    const [paymentMethod, setPaymentMethod] = useState(null); // Estado para el método de pago seleccionado
    const [includeIva, setIncludeIva] = useState(false); // Estado para incluir o no el IVA

    const subtotal = budgetToPay?.total || 0; // Obtener el subtotal del presupuesto
    const calculatedIva = budgetToPay?.impuesto; // Calcular el IVA
    const total = includeIva ? subtotal + calculatedIva : subtotal; // Calcular el total dependiendo del IVA

    // Efecto para limpiar los estados cuando se cierra el modal
    const resetForm = () => {
        setPaymentMethod(null);
        setIncludeIva(false);
    };

    useEffect(() => {
        console.log('Métodos de pago disponibles:', paymentMethods); // Imprimir métodos de pago en la consola
    }, [paymentMethods]);

    const handlePaymentMethodSelect = (methodId) => {
        setPaymentMethod(methodId);
    };

    const handleConfirm = async () => {
        if (!paymentMethod) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Por favor, seleccione un método de pago.',
                confirmButtonColor: '#d33',
            });
            return;
        }

        try {
            // Llamar a la función para pagar el presupuesto
            await payBudget(budgetToPay.id, {
                monto_total_a_pagar: total,
                metodo_pago: paymentMethod,
            });

            // Mostrar alerta de éxito
            Swal.fire({
                icon: 'success',
                title: 'Pago realizado',
                text: 'El presupuesto se ha cobrado correctamente.',
                confirmButtonColor: '#3085d6',
            });

            setUpdateList(true);
            closeBudgetToPay();
            resetForm(); // Resetear el formulario al cerrar
        } catch (error) {
            // Manejo de errores
            const errorMessage =
                error.response?.data?.system_message || 'Ha ocurrido un error inesperado.';

            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: errorMessage,
                confirmButtonColor: '#d33',
            });

            closeBudgetToPay();
            resetForm(); // Resetear el formulario incluso en caso de error
        }
    };

    const handleClose = () => {
        resetForm(); // Resetear el formulario
        closeBudgetToPay(); // Cerrar el modal
    };

    return (
        <Dialog
            visible={!!budgetToPay}
            onHide={handleClose} // Llama a `handleClose` al cerrar el modal
            header="Realizar Pago"
            style={{ width: '50vw' }}
        >
            <div className="pay-modal">
                <div className="payment-methods">
                    <h3>Método de Pago</h3>
                    <div className="methods-grid" style={{ display: 'flex', justifyContent: 'space-around', gap: '10px' }}>
                        {paymentMethods.map((method) => (
                            <Button
                                key={method.id}
                                label={method.nombre}
                                className={`p-button ${paymentMethod === method.id ? 'p-button-success' : 'p-button-secondary'}`}
                                onClick={() => handlePaymentMethodSelect(method.id)}
                            />
                        ))}
                    </div>
                </div>

                <div className="iva-toggle">
                    <h3>Agregar IVA</h3>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Checkbox
                            inputId="includeIva"
                            checked={includeIva}
                            onChange={(e) => setIncludeIva(e.checked)}
                        />
                        <label htmlFor="includeIva">Cobrar IVA</label>
                    </div>
                </div>

                <div className="totals">
                    <h3>Resumen</h3>
                    <p>Subtotal: ${subtotal?.toFixed(2)}</p>
                    {includeIva && <p>IVA ({iva}%): ${calculatedIva?.toFixed(2)}</p>}
                    <p>Total: ${total?.toFixed(2)}</p>
                </div>

                <div className="actions" style={{ display: 'flex', justifyContent: 'space-around', gap: '10px' }}>
                    <Button
                        label="Cancelar"
                        className="p-button-danger"
                        onClick={handleClose} // Usar la función `handleClose`
                    />
                    <Button
                        label="Confirmar"
                        className="p-button-success"
                        onClick={handleConfirm}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export default PayModal;
