
import Cookies from 'js-cookie';
import api from './axiosConfig'

// Función para obtener los datos generales
export const getGeneralData = async () => {
    try {
        // Obtener el token de la cookie o almacenamiento local
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token de autenticación no disponible');
        }

        // Realizar la solicitud GET a la API
        const response = await api.get('/datos_generales/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}` // Incluir el token de autorización
            }
        });

        // Si la respuesta es exitosa, almacenamos los datos en la cookie y los retornamos
        const data = response.data;
        return data;
    } catch (error) {
        console.error('Error al obtener los datos generales:', error);
        throw error; // Lanza el error para manejarlo en el componente
    }
};
