import React from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import './appSidebar.css';
import UserComponent from './UserComponent';
import { menuItems } from '../../helpers/info';

const AppSidebar = ({ sidebarVisible, setSidebarVisible }) => {
    const navigate = useNavigate(); // Inicializar useNavigate

    // Función para manejar la navegación
    const handleNavigate = (route) => {
        setSidebarVisible(false); // Cerrar el sidebar
        navigate(route); // Redirigir a la ruta especificada
    };

    return (
        <Sidebar
            visible={sidebarVisible}
            onHide={() => setSidebarVisible(false)}
            className="sidebar"
            showCloseIcon={false}
        >
            <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className="sidebar-header">
                        <h2>Electro Hogar</h2>
                        <Button
                            icon="pi pi-angle-left"
                            onClick={() => setSidebarVisible(false)}
                            className="p-button-text p-button-rounded custom-button-back"
                        />
                    </div>

                    <div className="sidebar-content">
                        <ul style={{ padding: "0 5px" }}>
                            <div key={"Inicio"} className="menu-item item-home" onClick={() => handleNavigate("/home")}>
                                <a

                                    className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
                                >
                                    <i className={'pi pi-home'}></i>
                                    <span style={{ marginLeft: "1rem" }} className="font-medium">{"Inicio"}</span>
                                </a>
                            </div>
                            {menuItems.map((item) => (
                                <div key={item.label} className="menu-item" onClick={() => handleNavigate(item.route)}>
                                    <a

                                        className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
                                    >
                                        <i className={item.icon}></i>
                                        <span style={{ marginLeft: "1rem" }} className="font-medium">{item.label}</span>
                                    </a>
                                </div>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className='sidebar-footer'>

                    <UserComponent setSidebarVisible={setSidebarVisible} drop="up" />
                </div>
            </div>
        </Sidebar>
    );
};

export default AppSidebar;
