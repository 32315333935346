import React, { useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';

const CcForm = ({ client, onSave, onCancel }) => {
    const [plazoCobro, setPlazoCobro] = useState(client?.plazo_cobro || null);
    const [limite, setLimite] = useState(client?.limite || null);

    const handleSave = () => {
        if (!plazoCobro || !limite) {
            onSave({ error: true, message: "Los campos Plazo de Cobro y Límite son obligatorios." });
            return;
        }
        onSave({ plazo_cobro: plazoCobro, limite });
    };

    return (
        <div>
            <h3>{`Cliente: ${client?.razon_social || client?.nombre} ${client?.apellido}`}</h3>
            <div className="p-field">
                <label htmlFor="plazoCobro">Plazo de Cobro (días)</label>
                <InputNumber
                    id="plazoCobro"
                    value={plazoCobro}
                    onValueChange={(e) => setPlazoCobro(e.value)}
                    placeholder="Ingrese el plazo de cobro"
                />
            </div>
            <div className="p-field">
                <label htmlFor="limite">Límite ($)</label>
                <InputNumber
                    id="limite"
                    value={limite}
                    onValueChange={(e) => setLimite(e.value)}
                    placeholder="Ingrese el límite"
                    mode="currency"
                    currency="USD"
                    locale="es-AR"
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '20px' }}>
                <Button
                    label="Cancelar"
                    className="p-button-secondary"
                    onClick={onCancel}
                />
                <Button
                    label="Guardar"
                    icon="pi pi-save"
                    className="p-button-success"
                    onClick={handleSave}
                />
            </div>
        </div>
    );
};

export default CcForm;
