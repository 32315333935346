// src/app/services/articlesServices.js

// Importar api y Cookies
import api from '../../helpers/axiosConfig'; // Asegúrate de que la ruta sea correcta
import Cookies from 'js-cookie'; // Importa js-cookie

// Función para obtener los artículos
export const getArticulos = async () => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible'); // Manejo si el token no está disponible
        }

        const response = await api.get('/articulos/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`, // Incluir el token de autorización
            }
        });

        return response.data; // Devuelve los datos de la respuesta
    } catch (error) {
        throw error; // Lanza el error para que pueda ser manejado en el componente
    }
};

// Función para editar un artículo
export const editArticle = async (id, data) => {

    console.log(data);

    const newData = {
        descripcion: data.descripcion,
        costo: data.costo,
        cantidad_disponible: data.cantidad_disponible,
    }
    

    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible'); // Manejo si el token no está disponible
        }

        const response = await api.patch(`/articulos/${id}/`, newData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`, // Incluir el token de autorización
            }
        });

        return response; // Devuelve los datos de la respuesta
    } catch (error) {
        throw error; // Lanza el error para que pueda ser manejado en el componente
    }
};

// Función para crear un artículo
export const createArticle = async (data) => {

    try {

        const newData = { 
            ...data,
             presentacion: data.presentacion.id,
             proveedor: data.proveedor.id,
             costo: parseInt(data.costo),
             cantidad_disponible: parseInt(data.cantidad_disponible),
             cantidad_afectada: 0
             }

        console.log('====================================');
        console.log(newData);
        console.log('====================================');

        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible'); // Manejo si el token no está disponible
        }

        const response = await api.post('/articulos/', newData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`, // Incluir el token de autorización
            }
        });

        return response; // Devuelve los datos de la respuesta
    } catch (error) {
        console.log(error.response);
        
        throw error; // Lanza el error para que pueda ser manejado en el componente
    }
};


// Función para eliminar un artículo
export const deleteArticle = async (id) => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible'); // Manejo si el token no está disponible
        }

        const response = await api.delete(`/articulos/${id}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`, // Incluir el token de autorización
            }
        });

        return response; // Devuelve la respuesta en caso de éxito
    } catch (error) {
        throw error; // Lanza el error para que pueda ser manejado en el componente
    }
};

// Función para obtener las presentaciones de los artículos
export const getPresentacionesArticulos = async () => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible'); // Manejo si el token no está disponible
        }

        const response = await api.get('/presentacion_articulos/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`, // Incluir el token de autorización
            }
        });

        return response.data; // Devuelve los datos de la respuesta
    } catch (error) {
        console.log(error.response);
        
        throw error; // Lanza el error para que pueda ser manejado en el componente
    }
};