import React, {useEffect} from 'react';
import logo from '../../img/logo_byn.png';
import { useGeneral } from '../../context/GeneralContext';
import { formatMoney } from '../../helpers/generalhelpers';

const PdfContent = ({ budgetDetails, showIva }) => {
    const { generalData } = useGeneral(); // Obtener los datos generales, incluyendo IVA

    const totalPlusIva = budgetDetails?.total + budgetDetails?.impuesto; // total con IVA

    return (
        <div id="pdf-content" style={{ padding: '4vw', fontFamily: 'Arial', fontSize: '0.9vw', border: "1px solid #cbcbcb" }}>
            {/* Encabezado del presupuesto */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src={logo} // Reemplaza con el logo de tu empresa
                        alt="Logo"
                        style={{ width: '2vw', marginRight: '0.5vw' }} // Tamaño del logo dependiente de vw
                    />
                    <h2 style={{ margin: 0, fontSize: '1.5vw' }}>Electro Hogar</h2>
                </div>
                <div style={{ textAlign: 'right' }}>
                    <h3 style={{ fontSize: '1.2vw' }}>Presupuesto N°: {budgetDetails?.codigo_presupuesto}</h3>
                    <p style={{ fontSize: '1vw' }}>Válido hasta: {budgetDetails?.validez}</p>
                </div>
            </div>

            {/* Datos del cliente */}
            <div style={{ marginTop: '1vw' }}>
                <p style={{ fontSize: '1.2vw' }}>
                    <strong>Cliente:</strong>{' '}
                    {budgetDetails?.cliente_detalles?.empresa
                        ? ` ${budgetDetails?.cliente_detalles?.razon_social}` // Si es empresa, mostramos la razón social
                        : ` ${budgetDetails?.cliente_detalles?.nombre} ${budgetDetails?.cliente_detalles?.apellido}`} {/* Si no es empresa, mostramos el nombre y apellido */}
                </p>
                <p style={{ fontSize: '1.2vw' }}>
                    <strong>Domicilio:</strong> {budgetDetails?.cliente_detalles?.domicilio}
                </p>
                <p style={{ fontSize: '1.2vw' }}>
                    <strong>Teléfono:</strong> {budgetDetails?.cliente_detalles?.telefono}
                </p>
            </div>

            {/* Detalles de los productos */}
            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '1vw' }}>
                <thead>
                    <tr style={{ backgroundColor: '#f5f5f5', textAlign: 'left' }}>
                        <th style={{ border: '1px solid #ddd', padding: '0.5vw', fontSize: '1.2vw' }}>SKU - Código</th>
                        <th style={{ border: '1px solid #ddd', padding: '0.5vw', fontSize: '1.2vw' }}>Descripción</th>
                        <th style={{ border: '1px solid #ddd', padding: '0.5vw', fontSize: '1.2vw' }}>Presentación</th>
                        <th style={{ border: '1px solid #ddd', padding: '0.5vw', fontSize: '1.2vw' }}>Precio</th>
                        <th style={{ border: '1px solid #ddd', padding: '0.5vw', fontSize: '1.2vw' }}>Cantidad</th>
                        <th style={{ border: '1px solid #ddd', padding: '0.5vw', fontSize: '1.2vw' }}>Importe Final</th>
                    </tr>
                </thead>
                <tbody>
                    {budgetDetails?.items_presupuesto?.map((item, index) => (
                        <tr key={index}>
                            <td style={{ border: '1px solid #ddd', padding: '0.5vw', fontSize: '1.2vw' }}>{item.articulo_sku}</td>
                            <td style={{ border: '1px solid #ddd', padding: '0.5vw', fontSize: '1.2vw' }}>{item.articulo_descripcion}</td>
                            <td style={{ border: '1px solid #ddd', padding: '0.5vw', fontSize: '1.2vw' }}>{item.articulo_presentacion}</td>
                            <td style={{ border: '1px solid #ddd', padding: '0.5vw', fontSize: '1.2vw' }}>
                                {formatMoney(item.precio_unitario)}
                            </td>
                            <td style={{ border: '1px solid #ddd', padding: '0.5vw', fontSize: '1.2vw' }}>{item.cantidad}</td>
                            <td style={{ border: '1px solid #ddd', padding: '0.5vw', fontSize: '1.2vw' }}>
                                {formatMoney(item.total)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Mostrar IVA si el prop showIva es true */}
            {showIva && generalData?.iva && (
                <div style={{ marginTop: '1vw', fontSize: '1.2vw', textAlign: 'right' }}>
                    <p>Subtotal: {formatMoney(budgetDetails?.total)}</p>
                    <p>IVA ({generalData.iva}%): {formatMoney(budgetDetails?.impuesto)}</p>
                    <p>
                        <strong>Total: {formatMoney(totalPlusIva)}</strong>
                    </p>
                </div>
            )}

            {/* Si no se debe mostrar IVA, solo mostrar el total */}
            {!showIva && (
                <div style={{ marginTop: '1vw', fontSize: '1.2vw', textAlign: 'right' }}>
                    <p>
                        <strong>Total: {formatMoney(budgetDetails?.total)}</strong>
                    </p>
                </div>
            )}

        </div>
    );
};

export default PdfContent;
