import React from 'react';
import Articles from '../articles/Articles';
import BudgetOptions from './BudgetOptions';
import BudgetClient from './BudgetClient';
import BudgetTotal from './BudgetTotal';

const Budget = () => {
    return (
        <div>

            <BudgetClient />

            <Articles isBudget={true} />

            <BudgetTotal />

            <BudgetOptions />
        </div>
    );
};

export default Budget;