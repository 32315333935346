import React from 'react';

const PurchaseOrders = () => {
    return (
        <div>
            PurchaseOrders
        </div>
    );
};

export default PurchaseOrders;