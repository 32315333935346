// src/app/helpers/axiosConfig.js
import axios from 'axios';

// Crear una instancia de Axios con configuración predeterminada
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, 
  // baseURL: "http://localhost:8000/api", 
  headers: {
    'Content-Type': 'application/json',
  },
});

// Manejo de errores
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Puedes manejar errores globalmente aquí
    console.error('Axios error:', error);
    return Promise.reject(error);
  }
);

export default instance;
