import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserComponent from '../appSidebar/UserComponent';
import { menuItems } from '../../helpers/info';
import logo from '../../img/logo.png';
import './sectionHeader.css';
import { Button } from 'primereact/button';
import { useBudget } from '../../context/BudgetContext'; // Importar el hook del contexto

const SectionHeader = () => {
    const navigate = useNavigate();
    const location = useLocation(); // Obtener la ubicación actual
    const currentPath = location.pathname; // Ruta actual

    // Buscar el item correspondiente al path actual
    const currentItem = menuItems.find(item => item.route === currentPath);
    const title = currentItem ? currentItem.label : 'Inicio'; // Título predeterminado si no se encuentra

    // Acceder al contexto de presupuesto
    const { products } = useBudget(); // Obtener productos del contexto

    // Calcular la cantidad total de productos en el carrito
    const numberOfProducts = products?.length;

    return (
        <div style={{ margin: "0", padding: "10px 35px 10px 80px", display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #374151" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <img className="cursor-pointer logo-img" src={logo} onClick={() => navigate("/")} alt="Logo" />
                <h1 style={{ margin: "0" }}>{title}</h1>
            </div>
            <div>
                <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                    <UserComponent drop="down" />
                    {currentPath === "/budget" ? (
                        <Button
                            icon="pi pi-list"
                            className="cart-button"
                            onClick={() => navigate("/articles")}
                            title="Articulos"
                        />
                    ) : (
                        <>
                            <Button
                                icon="pi pi-shopping-cart"
                                className="cart-button"
                                onClick={() => navigate("/budget")}
                                title="Presupuesto"
                            />
                            {numberOfProducts > 0 && ( // Mostrar cantidad de productos si es mayor que 0
                                <span className="product-count">{numberOfProducts}</span>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SectionHeader;
