import React, { useEffect, useState } from 'react';
import { getArticulos } from './articlesServices';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import './articles.css';
import FormArticle from './FormArticle';
import { useBudget } from '../../context/BudgetContext';
import { useGeneral } from '../../context/GeneralContext';
import { formatMoney } from '../../helpers/generalhelpers';
import { hasRole } from '../../helpers/usersHelper';

const Articles = ({ isBudget }) => {
    const { generalData } = useGeneral();
    const {
        addProduct,
        products,
        removeProductUnit,
        removeProduct,
        setQuantity,
        setMassivePrice,
        setProductPrice,
    } = useBudget();
    const [articles, setArticles] = useState([]);
    const [filteredArticles, setFilteredArticles] = useState([]);
    const [skuFilter, setSkuFilter] = useState('');
    const [descriptionFilter, setDescriptionFilter] = useState('');
    const [providerFilter, setProviderFilter] = useState('');
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [markups, setMarkups] = useState([]);

    const fetchArticulos = async () => {
        try {
            const res = await getArticulos();
            setArticles(res.registros);
            setFilteredArticles(res.registros);
        } catch (error) {
            console.error('Error al obtener los artículos:', error);
        }
    };

    useEffect(() => {
        fetchArticulos();
    }, []);

    useEffect(() => {
        setMarkups(generalData?.mark_ups || []);
    }, [generalData]);

    useEffect(() => {
        const filtered = articles.filter((article) => {
            const matchesSku = article.sku.toLowerCase().includes(skuFilter.toLowerCase());
            const matchesDescription = article.descripcion
                .toLowerCase()
                .includes(descriptionFilter.toLowerCase());
            const matchesProvider = article.proveedor_nombre
                .toLowerCase()
                .includes(providerFilter.toLowerCase());
            return matchesSku && matchesDescription && matchesProvider;
        });
        setFilteredArticles(filtered);
    }, [skuFilter, descriptionFilter, providerFilter, articles]);

    const handleCloseCreateDialog = () => setShowCreateDialog(false);
    const handleCloseEditDialog = () => setShowEditDialog(false);

    const renderActionButtons = (rowData) => {
        const existingProduct = products.find((product) => product.id === rowData.id);

        const handleQuantityChange = (e) => {
            const newQuantity = parseInt(e.target.value, 10);
            if (!isNaN(newQuantity) && newQuantity >= 0) {
                setQuantity({ product: rowData, quantity: newQuantity });
            }
        };

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {existingProduct ? (
                    <>
                        <input
                            type="number"
                            value={existingProduct.quantity}
                            onChange={handleQuantityChange}
                            style={{
                                width: '50px',
                                textAlign: 'center',
                                padding: '5px',
                                margin: '0 10px',
                            }}
                        />
                        {existingProduct.quantity === 1 ? (
                            <Button
                                icon="pi pi-trash"
                                className="p-button-rounded p-button-secondary"
                                onClick={() => removeProduct(rowData.id)}
                            />
                        ) : (
                            <Button
                                icon="pi pi-minus"
                                className="p-button-rounded p-button-secondary"
                                onClick={() => removeProductUnit(rowData.id)}
                            />
                        )}
                        <Button
                            icon="pi pi-plus"
                            className="p-button-rounded p-button-secondary"
                            onClick={() => addProduct(rowData)}
                        />
                    </>
                ) : (
                    <Button
                        icon="pi pi-cart-plus"
                        className="p-button-rounded p-button-primary"
                        onClick={() => addProduct(rowData)}
                    />
                )}
            </div>
        );
    };

    const renderPriceWithMarkup = (rowData, markup) => {
        const costo = rowData.costo;
        const markedPrice = costo * (1 + markup?.porcentaje / 100);
        const isSelectedPrice = rowData.markup === markup.id;

        const handlePriceClick = () => {
            setProductPrice(rowData.id, markup.id);
        };

        return (
            <span
                className={isSelectedPrice ? 'highlight-price' : ''}
                onClick={handlePriceClick}
                style={{ cursor: 'pointer' }}
            >
                {formatMoney(markedPrice)}
            </span>
        );
    };

    const handleHeaderClick = (pos) => {
        setMassivePrice(pos);
    };

    return (
        <div style={{ margin: 'auto', padding: '25px 0', maxWidth: '90vw' }}>
            <h2 style={{ textAlign: 'center' }}>Artículos</h2>

            {/* Filtros */}
            <div className="p-inputgroup" style={{ marginBottom: '20px' }}>
                <InputText
                    placeholder="Buscar por SKU"
                    className="input-filter-articles"
                    value={skuFilter}
                    onChange={(e) => setSkuFilter(e.target.value)}
                />
                <InputText
                    placeholder="Buscar por Descripción"
                    className="input-filter-articles"
                    value={descriptionFilter}
                    onChange={(e) => setDescriptionFilter(e.target.value)}
                />
                <InputText
                    placeholder="Buscar por Proveedor"
                    className="input-filter-articles"
                    value={providerFilter}
                    onChange={(e) => setProviderFilter(e.target.value)}
                />
            </div>

            <DataTable value={isBudget ? products : filteredArticles} paginator rows={10}>
                {!isBudget && hasRole(["ADMIN"]) && (
                    <Column
                        body={(rowData) => (
                            <Button
                                icon="pi pi-pencil"
                                className="p-button-rounded p-button-info"
                                onClick={() => {
                                    setSelectedArticle(rowData);
                                    setShowEditDialog(true);
                                }}
                            />
                        )}
                        header="Editar"
                        style={{ textAlign: 'center', width: '8rem' }}
                    />
                )}
                <Column field="sku" header="SKU - Código" />
                <Column field="descripcion" header="Descripción" />
                <Column field="proveedor_nombre" header="Proveedor" />
                <Column field="presentacion" header="Presentación" />
                <Column field="costo" header="Costo" body={(rowData) => formatMoney(rowData.costo)} />
                {markups.map((markup, pos) => (
                    <Column
                        key={markup.id}
                        header={
                            <div
                                style={{
                                    cursor: isBudget ? 'pointer' : 'default',
                                    color: isBudget ? '#007ad9' : 'inherit',
                                    textDecoration: isBudget ? 'underline' : 'none',
                                }}
                                onClick={isBudget ? () => handleHeaderClick(pos) : undefined}
                            >
                                {`Precio ${pos + 1}`}
                            </div>
                        }
                        body={(rowData) => renderPriceWithMarkup(rowData, markup)}
                    />
                ))}
                <Column field="cantidad_disponible" header="Stock Disponible" />
                {isBudget && (
                    <Column
                        header="Total"
                        body={(rowData) => formatMoney(rowData.total)}
                        style={{ fontWeight: 'bold' }}
                    />
                )}
                <Column body={renderActionButtons} />
            </DataTable>

            {hasRole(["ADMIN"]) &&
                <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px', gap: '12px' }}>
                    {!isBudget && (
                        <Button
                            icon="pi pi-plus"
                            label="Crear artículo"
                            className="p-button-rounded p-button-success"
                            onClick={() => setShowCreateDialog(true)}
                        />
                    )}
                </div>
            }

            {/* Dialog para crear artículo */}
            <Dialog
                style={{ width: '40vw' }}
                header="Crear Nuevo Artículo"
                visible={showCreateDialog}
                onHide={handleCloseCreateDialog}
            >
                <FormArticle close={handleCloseCreateDialog} refreshArticles={fetchArticulos} />
            </Dialog>

            {/* Dialog para editar artículo */}
            <Dialog
                style={{ width: '40vw' }}
                header="Editar Artículo"
                visible={showEditDialog}
                onHide={handleCloseEditDialog}
            >
                <FormArticle selectedArticle={selectedArticle} close={handleCloseEditDialog} refreshArticles={fetchArticulos} />
            </Dialog>
        </div>
    );
};

export default Articles;
