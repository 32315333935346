import React from 'react';
import SectionHeader from '../common/SectionHeader';
import HomeItemsContainer from './HomeItemsContainer';
import './home.css'

const Home = () => {
    return (
        <div style={{padding: "0"}}>


            <HomeItemsContainer />
        </div>
    );
};

export default Home;