import React, {useEffect} from 'react';
import { useBudget } from '../../context/BudgetContext';
import { formatMoney } from '../../helpers/generalhelpers';

const BudgetTotal = () => {

    const { getTotal } = useBudget();
    const total = getTotal() || 0
    
    return (
        total &&
        <div style={{
            margin: '20px auto',
            maxWidth: '90vw',
            display: "flex",
            justifyContent: "flex-end",
            fontSize: '20px',
            fontWeight: 'bold',
            backgroundColor: '#f4f4f4',
            padding: '10px 20px',
            borderRadius: '5px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            border: '1px solid #ccc'
        }}>
            <span>Total: </span>
            <span style={{ color: '#2e8b57', marginLeft: "10px" }}>
                {formatMoney(total)}
            </span>
        </div>
    );
};

export default BudgetTotal;
