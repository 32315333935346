// src/app/login/loginServices.js
import api from '../../helpers/axiosConfig'; // Asegúrate de que la ruta sea correcta

// Función para iniciar sesión
export const loginUser = async (credentials) => {
  try {
    const response = await api.post('/login', credentials); // Ajusta la ruta según tu API
    return response.data; // Devuelve los datos de la respuesta
  } catch (error) {
    throw error; // Lanza el error para que pueda ser manejado en el componente
  }
};

// Función para cerrar sesión
export const logoutUser = async (token) => {
  try {
    const response = await api.post('/logout', {}, {
      headers: {
        'Authorization': `Token ${token}`, // Incluye el token en los encabezados
        'Content-Type': 'application/json', // Establece el tipo de contenido
      }
    });
    return response.data; // Devuelve los datos de la respuesta
  } catch (error) {
    throw error; // Lanza el error para que pueda ser manejado en el componente
  }
};

// Función para solicitar el restablecimiento de contraseña
export const requestPasswordReset = async (email) => {
  try {
    const response = await api.post('/request_password_reset', { email }, {
      headers: {
        'Content-Type': 'application/json', // Establece el tipo de contenido
      }
    });
    return response.data; // Devuelve los datos de la respuesta
  } catch (error) {
    throw error; // Lanza el error para que pueda ser manejado en el componente
  }
};

// Función para restablecer la contraseña
export const resetPassword = async (token, newPassword) => {
  const data = {
    new_password: newPassword.password,
    token
   }
  
  try {
    const response = await api.post('/password_reset', data, {
      headers: {
        'Content-Type': 'application/json', // Establece el tipo de contenido
      }
    });
    return response.data; // Devuelve los datos de la respuesta
  } catch (error) {
    throw error; // Lanza el error para que pueda ser manejado en el componente
  }
};
