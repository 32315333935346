// src/app/pages/Home.jsx
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Importar useNavigate
import { Button } from 'primereact/button';
import { PrimeReactProvider } from 'primereact/api';
import AppSidebar from '../appSidebar/AppSidebar';
import { useAuth } from '../../context/AuthContext'; // Importar el contexto de autenticación
import './mainView.css'; // Asegúrate de que el archivo CSS esté importado
import SectionHeader from '../common/SectionHeader';
import { useGeneral } from '../../context/GeneralContext';

const MainView = ({ children }) => {

  const { fetchGeneralData } = useGeneral()

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const location = useLocation(); // Obtener la ubicación actual
  const navigate = useNavigate(); // Inicializa useNavigate
  const { user } = useAuth(); // Obtener el usuario del contexto

  // Verificar si estamos en la ruta /login
  const isResetPage = location.pathname.includes('/reset_password')
  const isLoginPage = location.pathname === '/login';
  const isHomePage = location.pathname === '/'; // Verificar si estamos en la ruta /

  useEffect(() => {
    if (!user && !isLoginPage && !isResetPage) {
      navigate('/login'); // Redirigir a /login si no hay usuario
    } else if (user && isHomePage) {
      navigate('/home'); // Redirigir a /home si el usuario está logueado y está en la ruta /
    }
  }, [user, isLoginPage, isHomePage, navigate]); // Ejecutar el efecto cuando el usuario cambie

  useEffect(() => {
    fetchGeneralData()
  }, [location])

  return (
    <PrimeReactProvider>
      <div className="general-container" style={{ display: "flex", flexDirection: "column" }}>
        {!isLoginPage && !isResetPage && (
          <Button
            icon="pi pi-angle-right"
            onClick={() => setSidebarVisible(true)}
            className="p-button-rounded"
            style={{ position: 'absolute', top: '22px', left: '10px', zIndex: 1000 }}
          />
        )}


        {!isLoginPage && !isResetPage && (
          <SectionHeader />
        )}

        <div style={{ width: "100%", height: "auto", backgroundColor: "#F9FAFB", padding: "0" }} className="content">
          {children}
        </div>
      </div>

      {/* Mostrar el sidebar solo si no estamos en la página de login */}
      {!isLoginPage && !isResetPage && (
        <AppSidebar sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible} />
      )}
    </PrimeReactProvider>
  );
};

export default MainView;
