import React, { useState, useEffect } from 'react';
import { Avatar } from 'primereact/avatar';
import { useAuth } from '../../context/AuthContext';
import { FaUserCircle } from 'react-icons/fa';
import Swal from 'sweetalert2'; // Importar SweetAlert2
import './userComponent.css'; // Asegúrate de tener el CSS para estilos adicionales
import { useGeneral } from '../../context/GeneralContext';
import { getUserRoles } from '../../helpers/usersHelper';

const UserComponent = ({ setSidebarVisible, drop }) => {
    const { user, logout } = useAuth(); // Obtener el usuario y la función de logout del contexto
    const { clearGeneralData } = useGeneral()
    const userData = user ? user.user_data : null; // Extraer solo user_data
    const [dropdownVisible, setDropdownVisible] = useState(false); // Estado para el dropdown

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleLogout = () => {
        if (setSidebarVisible) {
            setSidebarVisible(false); // Esconder el sidebar solo si se proporciona el prop
        }
        Swal.fire({
            text: '¿Estás seguro que deseas cerrar sesión?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, cerrar sesión',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                clearGeneralData()
                logout(); // Llama a la función logout del contexto
            }
        });
    };

    return (
        <div className="user-component" style={{ display: "flex", justifyContent: "center", padding: "0 5px", color: "#fff" }}>
            <div
                className=""
                onClick={toggleDropdown}
                style={{ backgroundColor: "grey", display: "flex", justifyContent: "space-between", alignItems: "center", borderRadius: "100px", width: "200px", height: "40px" }}
            >
                <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                    <Avatar
                        icon={<FaUserCircle />}
                        shape="circle"
                        className="bg-gray-300"
                        style={{ marginLeft: "5px" }}
                    />
                    <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
                        <span style={{ fontSize: "90%" }} className="font-bold">{userData ? userData.username : 'Invitado'}</span>
                        <span style={{ fontSize: "75%" }} className="user-role">{getUserRoles() || 'Sin rol asignado'}</span>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                    <span style={{ padding: "2px" }} className="dropdown-icon cursor-pointer">
                        <i className="pi pi-angle-down" style={{ fontSize: '1rem' }}></i>
                    </span>
                </div>
            </div>
            {dropdownVisible && (
                <div className={`dropdown-menu ${drop === 'up' ? 'up' : 'down'}`}>
                    <ul>
                        <li onClick={() => console.log('Perfil')}>Perfil</li>
                        <li onClick={() => console.log('Configuración')}>Configuración</li>
                        <li onClick={handleLogout}>Cerrar sesión</li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default UserComponent;
