import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { loginUser } from './authServices';
import Swal from 'sweetalert2';
import './login.css';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import logo from '../../img/logo.png';
import { Dialog } from 'primereact/dialog'; // Importar Dialog
import BlockedUser from './BlockedUser'; // Importar el componente

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();
  const [showBlockedModal, setShowBlockedModal] = useState(false); // Estado para el modal
  const [isUserBlocked, setIsUserBlocked] = useState(false); // Estado para verificar si el usuario está bloqueado

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await loginUser({ username, password });
      login(response);
      setErrorMessage('');

      Swal.fire({
        icon: 'success',
        title: 'Inicio de sesión',
        text: 'Has iniciado sesión correctamente.',
        timer: 2000,
        timerProgressBar: true,
        willClose: () => {
          navigate('/home');
        },
      });
    } catch (error) {
      console.error('Login error:', error);
      if (error.response) {
        setErrorMessage(error.response.data.system_message);
        if (error.response.status === 423) {
          setIsUserBlocked(true); // Marcar que el usuario está bloqueado
        }
      } else {
        setErrorMessage('Ocurrió un error inesperado');
      }
    }
  };

  const blockedUser = () => {
    setShowBlockedModal(true); // Cambia el estado para abrir el modal
  };

  const handleRecoverPassword = () => {
    // Abre el modal para recuperar la contraseña
    setShowBlockedModal(true);
  };

  useEffect(() => {
    setErrorMessage("")
  }, [username, password])

  return (
    <div className="login-container">
      <img className='logo-login' src={logo} alt="Logo" />
      <h1 className="title-login">Electro Hogar</h1>
      <div className="login-box">
        <h2 className='subtitle-login'>Inicio de sesión</h2>
        <form onSubmit={onSubmit}>
          <InputText
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Usuario"
            required
            className="input-field"
          />
          <InputText
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Contraseña"
            required
            className="input-field"
          />
          <Button type="submit" label="Iniciar Sesión" className="button-login" />
        </form>

        {errorMessage && (
          <p className="alert-message error-message">
            <i className="pi pi-exclamation-triangle"></i> {errorMessage}
          </p>
        )}
        {isUserBlocked && (
          <p >
            Para recuperar su contraseña haga clic <span className="recover-password" onClick={handleRecoverPassword} >aquí. </span>  
          </p>
        )}
      </div>

      {/* Modal de usuario bloqueado */}
      <Dialog header="Recuperar Contraseña" style={{ width: "30vw" }} visible={showBlockedModal} onHide={() => setShowBlockedModal(false)}>
        <BlockedUser />
      </Dialog>
    </div>
  );
};

export default Login;
