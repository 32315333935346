import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { createProvider, editProvider, getProvidersTypes } from './providersService';
import { useBudget } from '../../context/BudgetContext';
import Swal from 'sweetalert2';

const FormProvider = ({ close, refreshProviders, selectedProvider }) => {
    const { setBudgetClient } = useBudget();

    const [nombre, setNombre] = useState('');
    const [tipoProveedor, setTipoProveedor] = useState(null);
    const [divisa, setDivisa] = useState('ARS');
    const [telefono, setTelefono] = useState('');
    const [domicilio, setDomicilio] = useState('');
    const [razonSocial, setRazonSocial] = useState('');
    const [cuit, setCuit] = useState('');
    const [error, setError] = useState('');
    const [tipoProveedores, setTipoProveedores] = useState([]);

    // Cargar los tipos de proveedores al montar el componente
    useEffect(() => {
        const fetchTipoProveedores = async () => {
            try {
                const res = await getProvidersTypes();
                setTipoProveedores(res.registros);
            } catch (error) {
                console.error('Error al obtener los tipos de proveedores:', error);
                setError('Error al cargar los tipos de proveedores');
            }
        };
        fetchTipoProveedores();
    }, []);

    // Cargar los valores de `selectedProvider` al editar
    useEffect(() => {
        if (selectedProvider) {
            setNombre(selectedProvider.nombre || '');
            setTipoProveedor(tipoProveedores.find(tp => tp.id === selectedProvider.tipo_proveedor) || null);
            setDivisa(selectedProvider.divisa || 'ARS');
            setTelefono(selectedProvider.telefono || '');
            setDomicilio(selectedProvider.domicilio || '');
            setRazonSocial(selectedProvider.razon_social || '');
            setCuit(selectedProvider.cuit || '');
        }
    }, [selectedProvider, tipoProveedores]);

    // Manejar envío del formulario
    const handleSubmit = async (e) => {
        e.preventDefault();

        const providerData = {
            nombre,
            tipo_proveedor: tipoProveedor?.id,
            divisa,
            telefono,
            domicilio,
            razon_social: razonSocial,
            cuit
        };

        try {
            if (selectedProvider) {
                // Editar proveedor
                await editProvider(selectedProvider.id, providerData);
                Swal.fire({
                    icon: 'success',
                    title: 'Proveedor editado exitosamente',
                    showConfirmButton: false,
                    timer: 1500
                });
            } else {
                // Crear proveedor
                const res = await createProvider(providerData);
                if (res.status === 201) {
                    setBudgetClient(res.data);
                    Swal.fire({
                        icon: 'success',
                        title: 'Proveedor creado exitosamente',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            }
            refreshProviders();
            close();
        } catch (error) {
            console.error('Error al procesar el formulario:', error);
            setError(error.response?.data?.system_message || 'Error desconocido');
        }
    };

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit}>
                <div className="form-container">
                    <div className="form-group-client">
                        <span className="form-label">Nombre:</span>
                        <InputText
                            placeholder="Nombre del Proveedor"
                            className="input-field input-field-providers"
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                            required
                        />
                    </div>

                    <div className="form-group-client">
                        <span className="form-label">Tipo de Proveedor:</span>
                        <Dropdown
                            value={tipoProveedor}
                            options={tipoProveedores}
                            onChange={(e) => setTipoProveedor(e.value)}
                            optionLabel="nombre"
                            placeholder="Seleccionar Tipo de Proveedor"
                            className="input-field input-field-providers"
                            required
                        />
                    </div>

                    <div className="form-group-client">
                        <span className="form-label">Divisa:</span>
                        <select
                            className="input-field input-field-providers"
                            value={divisa}
                            onChange={(e) => setDivisa(e.target.value)}
                            required
                        >
                            <option value="ARS">ARS</option>
                            <option value="USD">USD</option>
                            <option value="EUR">EUR</option>
                        </select>
                    </div>

                    <div className="form-group-client">
                        <span className="form-label">Teléfono:</span>
                        <InputText
                            placeholder="Teléfono"
                            className="input-field input-field-providers"
                            value={telefono}
                            onChange={(e) => setTelefono(e.target.value)}
                        />
                    </div>

                    <div className="form-group-client">
                        <span className="form-label">Domicilio:</span>
                        <InputText
                            placeholder="Domicilio"
                            className="input-field input-field-providers"
                            value={domicilio}
                            onChange={(e) => setDomicilio(e.target.value)}
                        />
                    </div>

                    <div className="form-group-client">
                        <span className="form-label">Razón Social:</span>
                        <InputText
                            placeholder="Razón Social"
                            className="input-field input-field-providers"
                            value={razonSocial}
                            onChange={(e) => setRazonSocial(e.target.value)}
                        />
                    </div>

                    <div className="form-group-client">
                        <span className="form-label">CUIT:</span>
                        <InputText
                            placeholder="CUIT"
                            className="input-field input-field-providers"
                            value={cuit}
                            onChange={(e) => setCuit(e.target.value)}
                        />
                    </div>
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', gap: "12px" }}>
                    <Button label="Cancelar" onClick={close} icon="pi pi-times" className="p-button-text" />
                    <Button label={selectedProvider ? "Editar" : "Agregar"} type="submit" icon="pi pi-check" className="p-button-text" />
                </div>
            </form>
        </div>
    );
};

export default FormProvider;
