import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useBudget } from '../../context/BudgetContext';
import { useGeneral } from '../../context/GeneralContext';
import { getBudgets, sendToCash, returnToDraft } from '../budget/budgetServices';
import Swal from 'sweetalert2';
import { formatMoney } from '../../helpers/generalhelpers';
import { hasRole } from '../../helpers/usersHelper';
import './budgetsList.css';

const BudgetsList = () => {
    const [budgets, setBudgets] = useState([]); // Presupuestos actuales
    const [loading, setLoading] = useState(true); // Estado de carga
    const [page, setPage] = useState(1); // Página actual
    const [totalPages, setTotalPages] = useState(1); // Total de páginas
    const [totalRecords, setTotalRecords] = useState(0); // Total de registros
    const rowsPerPage = 20; // Número de registros por página

    // Filtros
    const [filters, setFilters] = useState({
        codigo_presupuesto: '',
        nombre_completo: '',
        fecha_desde: '',
        fecha_hasta: '',
        estado_presupuesto: [], // Array de IDs seleccionados
    });

    const { openBudgetDetails, setUpdateList, updateList, openBudgetToPay } = useBudget();
    const { generalData } = useGeneral();
    const estados_presupuestos = generalData?.estados_presupuestos || [];

    useEffect(() => {
        if (updateList) {
            fetchBudgets(page);
            setUpdateList(false);
        }
    }, [updateList]);

    const fetchBudgets = async (page) => {
        try {
            setLoading(true);
            const data = await getBudgets(page, filters); // Pasar filtros
            setBudgets(data.results);
            setTotalPages(data.total_paginas); // Total de páginas
            setTotalRecords(data.total_registros); // Total de registros
        } catch (error) {
            console.error('Error al obtener los presupuestos:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBudgets(page);
    }, [page, filters]);

    const handlePageChange = (event) => {
        const newPage = event.page + 1; // PrimeReact usa 0-indexed, pero el backend usa 1-indexed
        setPage(newPage);
    };

    const handleFilterChange = (field, value) => {
        setFilters((prevFilters) => ({ ...prevFilters, [field]: value }));
    };

    const handleCheckboxChange = (id) => {
        setFilters((prevFilters) => {
            const updatedEstados = prevFilters.estado_presupuesto.includes(id)
                ? prevFilters.estado_presupuesto.filter((estado) => estado !== id)
                : [...prevFilters.estado_presupuesto, id];
            return { ...prevFilters, estado_presupuesto: updatedEstados };
        });
    };

    const clearFilters = () => {
        setFilters({
            codigo_presupuesto: '',
            nombre_completo: '',
            fecha_desde: '',
            fecha_hasta: '',
            estado_presupuesto: [],
        });
    };

    const handleViewClick = (budgetId) => {
        openBudgetDetails(budgetId);
    };

    const handleSendToCash = async (budgetId) => {
        const confirm = await Swal.fire({
            title: '¿Estás seguro?',
            text: '¿Deseas enviar este presupuesto a caja?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, enviar',
            cancelButtonText: 'Cancelar',
        });

        if (confirm.isConfirmed) {
            try {
                await sendToCash(budgetId);
                Swal.fire('Éxito', 'El presupuesto fue enviado a caja correctamente.', 'success');
                fetchBudgets(page);
            } catch (error) {
                console.error('Error al enviar a caja:', error);
            }
        }
    };

    const handleReject = async (budgetId) => {
        const confirm = await Swal.fire({
            title: '¿Estás seguro?',
            text: '¿Deseas rechazar este presupuesto y retornarlo a borrador?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, rechazar',
            cancelButtonText: 'Cancelar',
        });

        if (confirm.isConfirmed) {
            try {
                await returnToDraft(budgetId);
                Swal.fire('Éxito', 'El presupuesto fue retornado a borrador correctamente.', 'success');
                fetchBudgets(page);
            } catch (error) {
                console.error('Error al rechazar:', error);
            }
        }
    };

    const handleCharge = (budgetId) => {
        openBudgetToPay(budgetId);
    };

    const getEstadoDisplay = (id) => {
        const estado = estados_presupuestos.find((e) => e.id === id);
        if (estado) {
            const colorMap = {
                BORRADOR: 'gray',
                CAJA: '#1e8449',
                PAGADO: '#2471a3',
                ENTREGADO: '#d35400',
            };
            return <span style={{ color: colorMap[estado.nombre] || 'black', fontWeight: 'bold' }}>{estado.nombre}</span>;
        }
        return <span style={{ color: 'red' }}>Desconocido</span>;
    };

    const renderActions = (rowData) => {
        const estado = estados_presupuestos.find((e) => e.id === rowData.estado_presupuesto);
        if (!estado) return null;

        switch (estado.nombre) {
            case 'BORRADOR':
                if (hasRole(['ADMIN', 'VENDEDOR'])) {
                    return (
                        <Button
                            label="Enviar a caja"
                            icon="pi pi-wallet"
                            onClick={() => handleSendToCash(rowData.id)}
                            className="p-button-success"
                        />
                    );
                }
                break;
            case 'CAJA':
                return (
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                        {hasRole(['ADMIN', 'CAJA']) && (
                            <Button
                                label="Cobrar"
                                icon="pi pi-dollar"
                                onClick={() => handleCharge(rowData.id)}
                                className="p-button-primary"
                            />
                        )}
                        {hasRole(['ADMIN', 'CAJA']) && (
                            <Button
                                label="Rechazar"
                                icon="pi pi-times"
                                onClick={() => handleReject(rowData.id)}
                                className="p-button-danger"
                            />
                        )}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div style={{ width: '100%', padding: '3vw 5vw' }}>
            {/* Filtros */}
            <div className="form-container-budgets" style={{ marginBottom: '20px' }}>
                <div className="form-group-budgets">
                    <label className="form-label-budgets" htmlFor="codigo">
                        Buscar por Código
                    </label>
                    <input
                        id="codigo"
                        type="text"
                        className="input-field-budgets"
                        placeholder="Código de Presupuesto"
                        value={filters.codigo_presupuesto}
                        onChange={(e) => handleFilterChange('codigo_presupuesto', e.target.value)}
                    />
                </div>

                <div className="form-group-budgets">
                    <label className="form-label-budgets" htmlFor="cliente">
                        Buscar por Cliente
                    </label>
                    <input
                        id="cliente"
                        type="text"
                        className="input-field-budgets"
                        placeholder="Nombre, Apellido o Razón Social"
                        value={filters.nombre_completo}
                        onChange={(e) => handleFilterChange('nombre_completo', e.target.value)}
                    />
                </div>

                <div className="form-group-budgets">
                    <label className="form-label-budgets" htmlFor="fecha-desde">
                        Fecha Desde
                    </label>
                    <input
                        id="fecha-desde"
                        type="date"
                        className="input-field-budgets"
                        value={filters.fecha_desde}
                        onChange={(e) => handleFilterChange('fecha_desde', e.target.value)}
                    />
                </div>

                <div className="form-group-budgets">
                    <label className="form-label-budgets" htmlFor="fecha-hasta">
                        Fecha Hasta
                    </label>
                    <input
                        id="fecha-hasta"
                        type="date"
                        className="input-field-budgets"
                        value={filters.fecha_hasta}
                        onChange={(e) => handleFilterChange('fecha_hasta', e.target.value)}
                    />
                </div>

                <div className="form-group-budgets">
                    <label className="form-label-budgets">Estado</label>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                        {estados_presupuestos.map((estado) => (
                            <div key={estado.id} className="custom-row-budgets">
                                <input
                                    type="checkbox"
                                    id={`estado-${estado.id}`}
                                    checked={filters.estado_presupuesto.includes(estado.id)}
                                    onChange={() => handleCheckboxChange(estado.id)}
                                />
                                <label htmlFor={`estado-${estado.id}`} style={{ marginLeft: '5px' }}>
                                    {estado.nombre}
                                </label>
                            </div>
                        ))}
                    </div>
                    {/* Botón para limpiar filtros */}
                </div>
                    <div className="">
                        <Button
                            label="Limpiar Filtros"
                            icon="pi pi-filter-slash"
                            className="p-button-secondary"
                            onClick={clearFilters}
                            style={{ marginTop: '20px' }}
                        />
                    </div>

            </div>

            {/* Tabla */}
            <DataTable
                value={budgets}
                paginator
                rows={rowsPerPage}
                first={(page - 1) * rowsPerPage}
                totalRecords={totalRecords}
                lazy
                loading={loading}
                onPage={handlePageChange}
                style={{ width: '100%' }}
            >
                <Column field="codigo_presupuesto" header="Código" />
                <Column
                    field="created_at"
                    header="Fecha de Creación"
                    body={(rowData) => new Date(rowData.created_at).toLocaleDateString()}
                />
                <Column field="nombre_completo" header="Cliente" />
                <Column
                    field="total"
                    header="Total"
                    body={(rowData) => formatMoney(rowData.total)}
                />
                <Column
                    field="estado_presupuesto"
                    header="Estado"
                    body={(rowData) => getEstadoDisplay(rowData.estado_presupuesto)}
                />
                <Column
                    body={(rowData) => (
                        <Button
                            icon="pi pi-file-pdf"
                            className="p-button-text"
                            onClick={() => handleViewClick(rowData.id)}
                            title="Ver Detalles"
                        />
                    )}
                />
                <Column body={(rowData) => renderActions(rowData)} />
            </DataTable>
        </div>
    );
};

export default BudgetsList;
