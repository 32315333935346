import React, { useState } from 'react';
import { requestPasswordReset } from './authServices'; // Asegúrate de que la ruta sea correcta
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { Card } from 'primereact/card'; // Importar Card de PrimeReact
import './blockedUser.css'; // Puedes agregar estilos personalizados aquí

const BlockedUser = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleInputChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessage(''); // Resetear mensaje
        setError(''); // Resetear error

        try {
            const response = await requestPasswordReset(email);
            if (response.status === 'success') {
                setMessage(response.system_message); // Mensaje de éxito
            }
        } catch (error) {
            // Manejo de errores según el mensaje recibido

            if (error.response) {
                const { system_message } = error.response.data;
                setError(system_message); // Establecer mensaje de error
            } else {
                setError('Error al comunicarse con el servidor.'); // Mensaje de error genérico
            }
        }
    };

    return (
        <div>

            <form style={{ textAlign: "center" }} onSubmit={handleSubmit}>
                <p>Para recuperar su contraseña, ingrese su correo electrónico:</p>
                <div className="p-field">
                    <InputText
                        id="email"
                        value={email}
                        onChange={handleInputChange}
                        required
                        className="p-inputtext-sm"
                        placeholder="Correo Electrónico"
                    />
                </div>
                <Button type="submit" label="Enviar Solicitud" className="p-button-sm" style={{ marginTop: '1rem' }} />
                <div>

                    {message && (
                        <Message severity="success" text={message} style={{ marginTop: '1rem' }} />
                    )}
                    {error && (
                        <Message severity="error" text={error} style={{ marginTop: '1rem' }} />
                    )}
                </div>
            </form>

        </div>
    );
};

export default BlockedUser;
