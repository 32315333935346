import React, { useEffect, useState } from 'react';
import { getProviders } from './providersService'; // Cambiar a providersService
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { useBudget } from '../../context/BudgetContext';
import './providers.css'; // Puedes usar un archivo CSS similar a 'clients.css'
import FormProvider from './FormProvider';
import { hasRole } from '../../helpers/usersHelper';

const Providers = () => {
    const { setBudgetClient, client: clientInBudget } = useBudget();
    const [providers, setProviders] = useState([]);
    const [filteredProviders, setFilteredProviders] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [cuilFilter, setCuilFilter] = useState('');
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState(null);

    // Función para obtener los proveedores
    const fetchProviders = async () => {
        try {
            const res = await getProviders(); // Llamada a getProviders
            setProviders(res.registros); // Guarda los proveedores
            setFilteredProviders(res.registros); // Establece los proveedores filtrados
        } catch (error) {
            console.error('Error al obtener los proveedores:', error);
        }
    };

    // Llamada a la función al montar el componente
    useEffect(() => {
        fetchProviders();
    }, []);

    // Filtrar proveedores según el nombre y cuil
    useEffect(() => {
        const filtered = providers.filter(provider => {
            const matchesName = provider.razon_social?.toLowerCase().includes(nameFilter.toLowerCase()) || (provider.nombre && provider.nombre.toLowerCase().includes(nameFilter.toLowerCase()));
            const matchesCuil = provider.cuit.toLowerCase().includes(cuilFilter.toLowerCase());
            return matchesName && matchesCuil;
        });

        setFilteredProviders(filtered);
    }, [nameFilter, cuilFilter, providers]);

    // Función para abrir el modal de creación
    const openCreateDialog = () => {
        setShowCreateDialog(true); // Cambia el estado para abrir el modal
    };

    // Función para cerrar el modal de creación
    const closeCreateDialog = () => {
        setShowCreateDialog(false); // Cambia el estado para cerrar el modal
    };

    // Función para abrir el modal de edición
    const editProvider = (provider) => {
        setSelectedProvider(provider); // Establece el proveedor seleccionado
        setShowEditDialog(true); // Cambia el estado para abrir el modal de edición
    };

    // Función para cerrar el modal de edición
    const closeEditDialog = () => {
        setShowEditDialog(false); // Cambia el estado para cerrar el modal
        setSelectedProvider(null); // Resetea el proveedor seleccionado
    };

    // Función para mostrar las acciones
    const renderActionButtons = (rowData) => {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-secondary"
                    onClick={() => editProvider(rowData)} // Abre el modal con el proveedor seleccionado
                />
            </div>
        );
    };

    return (
        <div style={{ margin: 'auto', padding: "25px 0", maxWidth: '90vw' }}>
            <h2 style={{ textAlign: 'center' }}>Proveedores</h2>

            {/* Filtros */}
            <div className="p-inputgroup">
                <InputText className='input-filter-providers' placeholder="Buscar por CUIL" value={cuilFilter} onChange={(e) => setCuilFilter(e.target.value)} />
                <InputText className='input-filter-providers' placeholder="Buscar por Nombre o Razón Social" value={nameFilter} onChange={(e) => setNameFilter(e.target.value)} />
            </div>

            {/* Tabla de proveedores */}
            <DataTable
                value={filteredProviders}
                paginator
                rows={10}
            >
                <Column field="cuit" header="CUIT" />
                <Column field="razon_social" header="Razón Social" body={(rowData) => rowData.razon_social || `${rowData.nombre} ${rowData.apellido}`} />
                <Column field="nombre" header="Nombre" body={(rowData) => rowData.nombre} />
                <Column field="telefono" header="Teléfono" />
                {hasRole(["ADMIN"]) &&
                    <Column body={renderActionButtons} header="Acciones" />
                }
            </DataTable>

            {/* Botón para agregar un nuevo proveedor */}
            {hasRole(["ADMIN"]) &&
                <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px', gap: "12px" }}>
                    <Button
                        icon="pi pi-plus"
                        label="Nuevo Proveedor"
                        className="p-button-rounded p-button-success"
                        onClick={openCreateDialog}
                    />
                </div>
            }

            {/* Modal para crear proveedor */}
            <Dialog style={{ width: '40vw' }} header="Crear Nuevo Proveedor" visible={showCreateDialog} onHide={closeCreateDialog}>
                <FormProvider close={closeCreateDialog} refreshProviders={fetchProviders} />
            </Dialog>

            {/* Modal para editar proveedor */}
            <Dialog style={{ width: '40vw' }} header="Editar Proveedor" visible={showEditDialog} onHide={closeEditDialog}>
                <FormProvider close={closeEditDialog} refreshProviders={fetchProviders} selectedProvider={selectedProvider} />
            </Dialog>
        </div>
    );
};

export default Providers;
