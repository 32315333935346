// src/components/resetPassword/ResetPassword.jsx
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Importar useParams y useNavigate
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { resetPassword } from './authServices';
import logo from '../../img/logo.png'
import './resetPassword.css'

const ResetPassword = () => {
    const { token } = useParams(); // Obtener el token de la URL
    const navigate = useNavigate(); // Inicializar useNavigate
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        if (newPassword !== confirmPassword) {
            setError('Las contraseñas no coinciden');
            return;
        }

        try {
            const response = await resetPassword(token, { password: newPassword });
            if (response.status === 'success') {
                setMessage('Contraseña actualizada exitosamente. Puedes iniciar sesión ahora.');
                // Redirigir a la página de inicio de sesión después de un tiempo o al hacer clic en un botón
                setTimeout(() => {
                    navigate('/login'); // Redirigir a la página de inicio de sesión
                }, 3000);
            }
        } catch (error) {
            if (error.response) {                
                const system_message = error.response.data.new_password[0];
                setError(system_message);
            } else {
                setError('Error al comunicarse con el servidor.');
            }
        }
    };

    return (
        <div className="login-container">
            <img className='logo-login' src={logo} alt="Logo" />
            <h1 className="title-login">Electro Hogar</h1>
            <div className="login-box">
                <h2 className="subtitle-login">Restablecer contraseña</h2>
                <form onSubmit={handleResetPassword}>

                    <InputText
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="Nueva Contraseña"
                        required
                        className="input-field"
                    />

                    <InputText
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirmar Contraseña"
                        required
                        className="input-field"
                    />

                    <Button type="submit" label="Restablecer Contraseña" className="button-login" />
                </form>

                {message &&
                    <p className="alert-message success-message">
                        <i className="pi pi-exclamation-triangle"></i> {message}
                    </p>
                }
                {error &&
                    <p className="alert-message error-message">
                        <i className="pi pi-exclamation-triangle"></i> {error}
                    </p>
                }
            </div>
        </div>
    );
};

export default ResetPassword;
