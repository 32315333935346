import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown'; // Importar el componente Dropdown
import { editArticle, createArticle, deleteArticle } from './articlesServices';
import { getProviders } from '../providers/providersService'; // Asegúrate de importar la función
import { getPresentacionesArticulos } from './articlesServices'; // Importar la función para obtener las presentaciones
import Swal from 'sweetalert2'; // Importamos SweetAlert2

const FormArticle = ({ selectedArticle, close, refreshArticles }) => {
    const [formData, setFormData] = useState({
        sku: '',
        descripcion: '',
        proveedor: '',
        presentacion: '',
        costo: '',
        cantidad_disponible: ''
    });

    const [providers, setProviders] = useState([]); // Estado para almacenar los proveedores
    const [presentations, setPresentations] = useState([]); // Estado para almacenar las presentaciones
    const [message, setMessage] = useState('');

    // Obtener la lista de proveedores y presentaciones al montar el componente
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [providersRes, presentationsRes] = await Promise.all([
                    getProviders(), // Llamada para obtener los proveedores
                    getPresentacionesArticulos() // Llamada para obtener las presentaciones de artículos
                ]);
                console.log(providersRes);
                console.log(presentationsRes);

                setProviders(providersRes.registros); // Actualiza el estado con los proveedores
                setPresentations(presentationsRes.registros); // Actualiza el estado con las presentaciones
            } catch (error) {
                console.error('Error al obtener los proveedores o presentaciones:', error);
                setMessage('Error al cargar los proveedores o presentaciones');
            }
        };
        fetchData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const onAccept = async () => {
        try {
            if (selectedArticle) {
                // Edición de artículo
                await editArticle(selectedArticle.id, formData);
                setMessage('Artículo editado exitosamente');
                Swal.fire({
                    icon: 'success',
                    title: 'Artículo editado',
                    text: 'El artículo se ha editado correctamente.',
                    confirmButtonColor: '#3085d6'
                });
            } else {
                // Creación de artículo
                await createArticle(formData);
                setMessage('Artículo creado exitosamente');
                Swal.fire({
                    icon: 'success',
                    title: 'Artículo creado',
                    text: 'El artículo se ha creado correctamente.',
                    confirmButtonColor: '#3085d6'
                });
            }
            refreshArticles();
            close();
        } catch (error) {
            close();
            console.error('Error al procesar la acción:', error);
            setMessage(error.response.data.descripcion);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Hubo un problema al crear o editar el artículo.',
                confirmButtonColor: '#d33'
            });
        }
    };

    const handleDelete = async () => {
        close();
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "No podrás recuperar este artículo después de eliminarlo.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
            try {
                await deleteArticle(selectedArticle.id); // Llama a la función para eliminar el artículo
                setMessage('Artículo eliminado exitosamente');
                Swal.fire({
                    icon: 'success',
                    title: 'Artículo eliminado',
                    text: 'El artículo se ha eliminado correctamente.',
                    confirmButtonColor: '#3085d6'
                });
                refreshArticles(); // Refresca la lista de artículos después de eliminar
                close(); // Cierra el modal
            } catch (error) {
                console.error('Error al eliminar el artículo:', error);
                setMessage('Error al eliminar el artículo');
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Hubo un problema al eliminar el artículo.',
                    confirmButtonColor: '#d33'
                });
            }
        }
    };

    useEffect(() => {
        if (selectedArticle) {
            setFormData({
                sku: selectedArticle.sku || '',
                descripcion: selectedArticle.descripcion || '',
                proveedor: selectedArticle.proveedor_nombre || '',
                presentacion: selectedArticle.presentacion || '',
                costo: selectedArticle.costo || '',
                cantidad_disponible: selectedArticle.cantidad_disponible || ''
            });
        }
    }, [selectedArticle]);

    return (
        <div>
            <div className="form-container">
                <div className="form-group">
                    <span className="form-label">SKU - Código</span>
                    <InputText
                        placeholder="SKU - Código"
                        className={`input-field input-field-providers ${selectedArticle ? 'disabled-input' : ''}`}
                        name="sku"
                        value={formData.sku}
                        onChange={handleInputChange}
                        disabled={!!selectedArticle}
                    />
                </div>
                <div className="form-group">
                    <span className="form-label">Descripción</span>
                    <InputText
                        placeholder="Descripción"
                        className="input-field input-field-providers"
                        name="descripcion"
                        value={formData.descripcion}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <span className="form-label">Proveedor</span>
                    <Dropdown
                        placeholder="Seleccionar Proveedor"
                        value={formData.proveedor}
                        options={providers} // Lista de proveedores
                        onChange={(e) => setFormData({ ...formData, proveedor: e.value })}
                        optionLabel="nombre" // Campo visible para el usuario
                        className={`input-field input-field-providers ${formData.proveedor ? 'no-arrow' : ''
                            } ${selectedArticle ? 'disabled-input' : ''}`}
                        disabled={!!selectedArticle} // Deshabilitar cuando esté en modo de edición
                        filter // Habilitar búsqueda
                        filterBy="nombre" // Campo por el cual filtrar
                        showClear={!!formData.proveedor} // Mostrar "X" solo si hay un valor seleccionado
                    />
                </div>

                <div className="form-group">
                    <span className="form-label">Presentación</span>
                    <Dropdown
                        placeholder="Seleccionar Presentación"
                        value={formData.presentacion}
                        options={presentations} // Lista de presentaciones
                        onChange={(e) => setFormData({ ...formData, presentacion: e.value })}
                        optionLabel="descripcion" // Campo visible para el usuario
                        className={`input-field input-field-providers ${formData.presentacion ? 'no-arrow' : ''
                            } ${selectedArticle ? 'disabled-input' : ''}`}
                        disabled={!!selectedArticle} // Deshabilitar cuando esté en modo de edición
                        filter // Habilitar búsqueda
                        filterBy="descripcion" // Campo por el cual filtrar
                        showClear={!!formData.presentacion} // Mostrar "X" solo si hay un valor seleccionado
                    />
                </div>



                <div className="form-group">
                    <span className="form-label">Costo</span>
                    <InputText
                        placeholder="Costo"
                        className="input-field input-field-providers"
                        name="costo"
                        value={formData.costo}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <span className="form-label">Cantidad</span>
                    <InputText
                        placeholder="Cantidad"
                        className="input-field input-field-providers"
                        name="cantidad_disponible"
                        value={formData.cantidad_disponible}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            {message && <div style={{ color: 'green', marginTop: '10px' }}>{message}</div>} {/* Mensaje de respuesta */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', gap: "12px" }}>
                {selectedArticle &&
                    <Button label="Eliminar" onClick={handleDelete} icon="pi pi-trash" className="p-button-text" />
                }
                <Button label="Cancelar" onClick={close} icon="pi pi-times" className="p-button-text" />
                <Button label="Aceptar" onClick={onAccept} icon="pi pi-check" className="p-button-text" />
            </div>
        </div>
    );
};

export default FormArticle;
