import React from 'react';

const ReceiptNotes = () => {
    return (
        <div>
            ReceiptNotes
        </div>
    );
};

export default ReceiptNotes;