// src/app/services/usersServices.js

import api from '../../helpers/axiosConfig'; // Asegúrate de que la ruta sea correcta
import Cookies from 'js-cookie'; // Importa js-cookie

// Función para obtener la lista de usuarios
export const getUsers = async () => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible'); // Manejo si el token no está disponible
        }

        const response = await api.get('/usuarios/', {
            headers: {
                'Content-Type': 'application/json', // Establece el tipo de contenido
                'Authorization': `Token ${token}`, // Incluye el token de autorización
            }
        });

        return response.data; // Devuelve los datos de la respuesta
    } catch (error) {
        throw error; // Lanza el error para que pueda ser manejado en el componente
    }
};
